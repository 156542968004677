.product-line-container {
    grid-column: span 2;
    margin-top: 4px;
}

.product-line-grid {
    display: grid;
    grid-template-columns:  68px 1fr 120px 140px 200px;
    padding: 6px 2px;
    align-items: center;
    max-width: 900px;
}

.product-line-grid:nth-child(2n) {
    background-color: #F4F4F4;
}

.product-line-h1 {
    font-size: 20px;
    font-weight: 700;
    color: #4d4e50;
    margin: 0;
}

.product-line-grid .input {
    border-right: 0;
    height: 26px;
    padding: 0 4px;
    margin: 0;
    width: 100px;
    flex: none;
    font-size: 16px;
    text-align: right;
}

.product-line-grid .prod-selector-container {
    width: 160px;
}

.product-line-grid .selector-arrow-container {
    margin-right: 0;
    width: 30px;
}

.product-line-grid select {
    height: 26px;
    padding: 0 30px 0 8px;
    font-size: 13px;
}

.product-line-grid .button {
    margin: 0;
    height: 26px;
    font-size: 12px;
    display: flex;
    padding: 0 8px;
    width: 60px;
    text-align: center;
    justify-content: center;
}

.product-line-h2 {
    font-size: 16px;
    color: #4d4e50;
    margin: 0;
    font-weight: 500;
}

.product-line-grid .prod-input {
    margin: 0;
    display: flex;
    flex-direction: column;
}

.product-line-grid .prod-input-divider {
    display: none;
}

.product-line-grid .prod-selector-container {
    height: auto;
    margin: 0;
}

.product-line-grid .prod-input-quantity-container {
    margin: 0;
    height: auto;
}

.product-line-grid .prod-selector-container:has(> *) {
    margin-bottom: 4px;
}

.product-line-input {
    display: flex;
    gap: 6px;
}

.product-line-delete {
    height: 24px;
    width: 24px;
    margin: auto 0;
    cursor: pointer;
}

.product-line-delete:active {
    filter: brightness(0.9);
    transition: filter 0s;
}

.product-h-l {
    text-align: left;
}

.product-h-r {
    text-align: right;
}

.product-line-img-container {
    margin-left: 4px;
}

.product-line-img-container img {
    max-height: 60px;
    max-width: 60px;
}

.product-line-text-container {
    margin: 0 0 0 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
}

.product-line-code {
    color: #c4c4c4;
    font-size: 13px;
    font-weight: 700;
    margin: 0 ;
}

.product-line-name {
    color: #5a5a5d;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}

.product-line-case {
    color: #5a5a5d;
    font-size: 13px;
    margin: 0;
}

.product-line-price {
    text-align: right;
    color: #5a5a5d;
    font-size: 18px;
    font-weight: 700;
    margin: 0;
}

.product-line-price-mark-down {
    color: #c4c4c4;
    font-weight: 400;
    text-decoration: line-through;
    font-size: 13px;
}

.product-line-price-text {
    text-align: right;
    color: #c4c4c4;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
}

.product-line-price-unit-price {
    text-align: right;
    color: #c4c4c4;
    font-weight: 400;
    font-size: 13px;
    margin: 0;
}

.product-line-tag {
    color: white;
    font-weight: 600;
    font-size: 16px;
    border-radius: 3px;
    padding: 4px 0;
}

.product-line-sign-in-message {
    grid-column: span 2;
    text-align: right;
    margin-right: 12px;
    font-size: 14px;
    color: #5a5a5d;
    width: 120px;
    margin-left: auto;
}

@media (max-width: 900px) {

    .product-line-container {
        font-size: 14px;
    }

    .product-line-container .product-line-h1 {
        font-size: 14px;
    }

    .product-line-container .product-line-h2 {
        font-size: 13px;
    }

    .product-line-grid {
        grid-template-columns: 68px 1fr 90px;
        max-width: calc(100vw - 40px);
        padding: 12px 2px;
    }

    .product-line-grid :nth-child(4) {
        margin-right: 4px;
    }

    .product-line-grid :nth-child(5) {
        margin-left: 4px;
    }

    .product-line-tag {
        order: 1;
        grid-column: span 3;
        margin: 8px 4px;
    }

    .product-line-input-container {
        order: 2;
        grid-column: span 3;
    }

    .product-line-input {
        justify-content: flex-end;
    }

}
