
.button {
    background-color: var(--primary);
    background: var(--primary-grad);
    color: var(--highlight);
    font-weight: 500;
    font-size: 1rem;
    border-radius: var(--border-radius);
    padding: 10pt 8pt;
    align-items: center;
    cursor: pointer;
    text-align: center;
    box-sizing: border-box;
    font-family: var(--button-font-family);

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.button:hover {
    filter: brightness(1.1);
    transition: filter 0.3s;
}

.button:active {
    filter: brightness(0.9);
    transition: filter 0s;
}

.button.inactive {
    background: var(--inactive);
}

.button.inactive:hover {
    filter: none;
    cursor: default;
}

.button_clear {
    border: var(--primary) solid 1px;
    background: var(--primary-muted);
    color: var(--primary);
}

.button.button_clear:hover {
    filter: none;
    transition: filter 0.3s;
    border: var(--secondary) solid 1px;
    background: var(--secondary-muted);
    color: var(--secondary);
}

.button_clear_angry {
    border: var(--warning) solid 2px;
    background-color: rgba(0, 0, 0, 0);
    color: var(--warning);
}

.button-secondary {
    background: var(--secondary-grad);
    background-color: var(--secondary);
}

.input {
    box-sizing: border-box;
    border: 1px solid var(--primary);
    font-size: 1rem;
    padding: 0.5rem;
    background: var(--dark-highlight);
    border-radius: var(--border-radius);
    margin: 1px 1px;
}

.input-error input {
    border: 1px solid var(--warning);
    background: var(--warning-background);
}

.input:focus {
    outline: none !important;
    border: 2px solid var(--secondary);

    margin: 0 0;
    max-width: 100%;
}

.input-error:focus {
    border: 2px solid var(--warning);
}

textarea.input {
    resize: vertical;
    font-family: inherit;
}

.tab-row {
    display: flex;
    justify-content: center;
    gap: 70pt;
    border-bottom: 2px solid var(--inactive);
}

.tab {
    font-size: 1.5rem;
    padding: 0 10px 0 10px;
    margin-bottom: 0;
    cursor: pointer;
}

.tab-selected {
    color: var(--primary);
    border-bottom: 3px solid var(--primary);
}

.selector {
    border: 1pt solid var(--primary);
    border-radius: 4pt;
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

select {
    /*appearance: none;*/
    background-color: transparent;
    border: none;
    font-size: 1em;
    padding: 4pt 8pt;
    width: 100%;
}

.tile {
    background-color: var(--highlight);
    border-radius: 4pt;
    /*filter: drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.08));*/
    border: 1px solid #e5e5e5;
}

.info {
    color: var(--highlight);
    background-color: var(--secondary);
    padding: 6pt 4pt;
    border-radius: 4pt;
    font-size: 0.9em;
}

.loading {
    animation-duration: 1.8s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #f3f3f3 54%, #fafafa 86%);
    background-size: 1000px 640px;

    position: relative;
}

.button-loading {
    cursor: default;
}

.button-loading:hover {
    filter: none;
}

.horizontal-divider {
    width: 100%;
    height: 1px;
    background: var(--primary);
}

.hidden {
    display: none !important;
}

.upside-down {
    transform: rotate(180deg);
}

.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease;
}

.reverse {
    transform: rotate(0);
    transition: transform 0.3s ease;
}

.radio-select {
    border-radius: 4pt;
    border: var(--primary) solid 1px;
    cursor: pointer;
    padding: 4pt;

    margin-top: 1px;
    margin-bottom: 1px;
}

.radio-select:hover {
    transition: filter 0.3s;
    border: var(--secondary) solid 2px;

    margin-top: 0;
    margin-bottom: 0;
}

.radio-container {
    display: flex;
    flex-direction: column;
    gap: 8pt;
}

.radio-item {
    display: flex;
    gap: 4pt;
    cursor: pointer;
    align-items: center;
}

.radio-icon {
    background-color: var(--inactive);
    width: 16pt;
    height: 16pt;
    border-radius: 50%;
    transition: filter 0.3s;
}

.radio-item:hover .radio-icon {
    filter: brightness(0.6);
    transition: filter 0.3s;
}

.checked .radio-icon{
    background-color: var(--primary);
}

.radio-item.checked:hover .radio-icon {
    filter: none;
}

.input-error-msg {
    text-align: left;
    margin: 1pt 0;
    font-size: 0.8em;
    color: var(--warning);
}

.date-picker-wrapper div input {
    box-sizing: border-box;
    border: 1px solid var(--primary);
    font-size: 1rem;
    padding: 0.5rem;
    background: var(--dark-highlight);
    border-radius: var(--border-radius);
    margin: 1px 1px;
    text-align: center;
}

.date-picker-wrapper div input:focus {
    outline: none !important;
    border: 2px solid var(--secondary);

    margin: 0 0;
    max-width: 100%;
}

.date-picker-wrapper {
    text-align: left;
}

.oh-no-layout {
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas:
            "img txt"
            "btn btn";
    max-width: 600px;
    gap: 20pt;
    margin: 20pt auto;
}

.oh-no-img {
    grid-area: img;
    max-width: 100%;
}

.oh-no-header {
    grid-area: txt;
}

.oh-no-button {
    grid-area: btn;
}

.tile-header {
    background-color: var(--background);
    border-bottom: var(--primary) 1px solid;
    border-radius: 4pt 4pt 0 0;
}

.clickable-text {
    color: var(--secondary);
    text-decoration: underline;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.clickable-text-secondary {
    color: var(--secondary);
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
}


.clickable-p {
    color: var(--secondary);
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}

.img-container {
    position: relative;
    width: 140pt;
    height: 140pt;
    margin-left: auto;
    margin-right: auto;
    border-radius: var(--border-radius);
}

.infinite-display {
    display: flex;
    flex-wrap: wrap;
    gap: 6pt;
    box-sizing: border-box;
    padding: 0 4pt;
}

.back-to-top-container {
    position: sticky;
    height: 0;
    bottom: 0;
    overflow: visible;
}

.back-to-top-format {
    width: 200pt;
    left: 10pt;
    bottom: 10pt;
    position: absolute;
    display: flex;
    flex-direction: column-reverse;
}

.back-to-top-format .button {
    background: var(--secondary-grad);
    background-color: var(--secondary);
}

.back-to-top-hidden {
    opacity: 0;
    transform: translateY(200pt);
    transition: transform 0.3s;
}

.back-to-top-visible {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.2s;
}

.react-datepicker {
    font-family: inherit !important;
    background-color: var(--highlight) !important;
    border: 1px solid #5a5a5d !important;
    color: var(--text-main) !important;
}

.react-datepicker__day--selected {
    background-color: var(--secondary) !important;
}

.react-datepicker__header {
    background-color: var(--background) !important;
    border-bottom: solid 1px var(--primary-muted) !important;
}

.month-picker .react-datepicker {
    font-size: 0.9rem !important;
}

.react-datepicker__month-text--keyboard-selected {
    background-color: var(--secondary) !important;
    color: var(--highlight) !important;
    border-radius: 3px !important;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__month-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__quarter-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range),
.react-datepicker__year-text--in-selecting-range:not(.react-datepicker__day--in-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--in-range) {
    background-color: var(--primary) !important;
    color: var(--highlight) !important;
}

.month-picker {
    display: flex;
    flex-direction: row;
    gap: 8pt;
    align-items: center;
}

@media (max-width: 768px) {
    .oh-no-layout {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas:
            "img"
            "txt"
            "btn";
        max-width: 600px;
        gap: 0;
        padding: 10pt;
    }

    .oh-no-layout img {
        max-height: 140pt;
        margin: 0 auto;
    }

    .date-picker-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .date-picker-wrapper div input {
        width: 100%;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .date-picker-wrapper .button {
        width: 100%;
    }

}
