
.promo-rerun-container {

}

.promo-rerun-container .input-form-sub-heading {
    display: none;
}

.promo-rerun-container .calendar {
    margin: 30px auto;
}
