
@media (max-width: 768px) {

    .page-specific-nav {
        grid-area: account-navbar;
        display: flex;
        background-color: var(--highlight);
        border-right: #EBEAE9 solid 1px;
        box-sizing: border-box;
        height: auto;
        flex-direction: row;
        border-right: none;
        border-bottom: 1px solid #EBEAE9;
        overflow-x: scroll;
        align-items: baseline;
        justify-content: space-evenly;
        z-index: 100;
    }

    .page-specific-nav-option {
        text-align: left;
        background-color: var(--highlight);
        border-radius: 3pt;
        cursor: pointer;
        padding: 8pt 8pt;
        flex: none ;
        font-size: 16px;
        color: #838485;
        font-weight: bold;
    }


}
